@font-face {
  font-family: 'ITCAvantGardeBK';
  src: url('fonts/itcavantgardestd-bk.eot');
  src: url('fonts/itcavantgardestd-bk.eot?#iefix') format('embedded-opentype'),
    url('fonts/itcavantgardestd-bk.woff') format('woff'),
    url('fonts/itcavantgardestd-bk.svg') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeBold';
  src: url('fonts/itcavantgardestd-bold.eot');
  src: url('fonts/itcavantgardestd-bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/itcavantgardestd-bold.woff') format('woff'),
    url('fonts/itcavantgardestd-bold.svg') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'ITCAvantGardeMd';
  src: url('fonts/ITCAvantGardePro-Md.woff2') format('woff2'), 
    url('fonts/ITCAvantGardePro-Md.woff') format('woff'), 
    url('fonts/ITCAvantGardePro-Md.ttf') format('truetype');
}