.footer-wrapper {
	background: $black;
	color: $white;
	font-family: $medium-font-family;
	padding: rem-calc(40 0);

	p {
		margin-bottom:0;
	}

	.footer-extra-links {
		padding-top: $global-padding;
		li {
			a {
				color:$white;
				border-right: solid 2px $white;
				padding-top:0;
				padding-bottom:0;

				&:hover {
					text-decoration: underline;
				}
			}
			&:last-child {
				a {
					border-right:none;
				}
			}
		}
	}
}