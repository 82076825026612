.menu-bar {
	position: fixed;
	background: $black;
	width:100%;
	top:0;
	z-index:9999;
	img {
		width:153px;
		padding-left: 30px;
		margin-top:6px;
	}
}
.hero-image {
	height: rem-calc(300);
	background: url(../img/basic-hero-automotive.jpg) center center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: rem-calc(82);
	// border-bottom: solid 5px get-color(flex-blue);
}