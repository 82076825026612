@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import "custom-fonts";
@import "fontawesome/font-awesome";
@import 'button-override';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
//
// @include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


/*----------  Text color  ----------*/
// Colors
@each $name, $color in $button-palette {
	&.color-#{$name} {
		color: $color !important;
	}
}

/*----------  Bold fonts  ----------*/
strong, b, h1, h2, h3, h4, h5, h6 {
	font-family: $bold-font-family;
}

/*----------  Buttons  ----------*/
.button {
	// Colors
    @each $name, $color in $button-palette {
	    @if $button-fill != hollow {
	        &.#{$name} {
	          	&.alt-color {
		        	$text-color: color-pick-alt-contrast($color, ($button-color-alt, $button-color));

		        	color: $text-color !important;
		        	&:hover {
		        		color: $text-color !important;
		        		text-decoration: none;
		        	}
		        }
	        }
	    }
	    &.#{$name} {
    		&.hollow {
    			&:hover {
	        		background: $color;
	        		color: color-pick-contrast($color, ($button-color, $button-color-alt));
	        		text-decoration: none;
	        	}
	        	&.alt-hollow-color {
	        		&:hover {
	        			color: color-pick-alt-contrast($color, ($button-color-alt, $button-color)) !important;
	        		}
	        	}
    		}
        }
    }

    &.radius {
    	border-radius: $global-big-radius;
    }
    &.rounded {
    	border-radius: 1000px;
    	padding: 0.85em 2em;
    }
}

.body-container {
	background:$white;
	padding-top: $global-margin*3;
	padding-bottom: $global-margin;
}

/*=================================================================
=            Here comes the specific components styles            =
=================================================================*/

@import "components/hero";
@import "components/accordion";
@import "components/footer";

/*=====  End of Here comes the specific components styles  ======*/

@import "pages/homologation";
@import "pages/vw";
@import "pages/bmw";
