
.accordion {
	.accordion-item {
		.accordion-title {
			background: $light-gray;
			border: solid 2px #cecece;
			font-family: $bold-font-family;
			font-size: rem-calc(20);
			&:before {
				right: 2rem;
				font-family: $body-font-family;
				font-size: rem-calc(30);
				top: calc(50% - 5px);
			}
		}
		&:first-child > :first-child {
			border-radius: 0;
		}
	}
}