.each-download {
	.fa-download {
		padding-left: rem-calc(5);
	}

	a.button {
		@include breakpoint(small only) {
			display: inline-block;
			width: auto;
    	}
	}
}